


/* popup table */
.ai-widget-popup .popup-contents{
  position:relative;
}
.ai-widget-popup .popup-contents .popup-contents-top{
  margin:0 0 30px 0;
}
.ai-widget-popup .popup-contents h4 {
  font-size:15px;
  padding:17px 0 7px 0;
}
.ai-widget-popup .popup-contents .popup-contents-top table{
  width:100%;
  border-collapse:collapse;
}
.ai-widget-popup .popup-contents .popup-contents-top tr{
  font-size:0;
  text-align:center;
}
.ai-widget-popup .popup-contents .popup-contents-top tr th{
  width:auto;
  font-size:13px;
  font-weight:400;
  color:#000000;
  padding:10px;
  background:#EFF2F7;
  border:1px solid #EAEAEA;
}
.ai-widget-popup .popup-contents .popup-contents-top tr td{
  font-size:13px;
  font-weight:400;
  color:#000000;
  border:1px solid #EAEAEA;
  padding:10px;
}
.ai-widget-popup .popup-contents .popup-contents-top tr td .member-icon{
  position:relative;
  display:inline-block;
  height:18px;
  padding:0 4px;
  box-sizing:border-box;
  text-align:center;
  font-size:11px;
  font-weight:400;
  line-height:18px;
  margin:0 0 0 8px;
  vertical-align:top;
}
.ai-widget-popup .popup-contents .popup-contents-top tr td .npay-member{
  color:#5664D2;
  border:1px solid #5664D2;
}
.ai-widget-popup .popup-contents .popup-contents-top tr td.review-type{
  font-size:0;
}
.ai-widget-popup .popup-contents .popup-contents-top tr td.review-type span{
  display:inline-block;
  height:18px;
  padding:0 4px;
  background:#4285F4;
  text-align:center;
  font-size:11px;
  font-weight:400;
  color:#ffffff;
  line-height:18px;
}
.ai-widget-popup .popup-contents .popup-contents-main{
  margin:0 0 20px 0;
}
.ai-widget-popup .popup-contents .popup-contents-main table{
  width:100%;
  border-collapse:collapse;
}
.ai-widget-popup .popup-contents .popup-contents-main table thead th{
  height:46px;
  background:#EFF2F7;
  text-align:center;
  font-size:13px;
  font-weight:500;
  color:#000000;
  padding:0;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td{
  padding:10px;
  border:1px solid #EAEAEA;
  text-align:center;
  font-size:12px;
  font-weight:400;
  color:#000000;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .posting-state{
  font-weight:700;
  color:#4285F4;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .posting-select{
  display:inline-block;
  width:138px;
  height:38px;
  border:1px solid #CED4DA;
  box-sizing:border-box;
  padding:0 25px 0 12px;
  font-size:13px;
  font-weight:400;
  color:#000000;
  line-height:38px;
  vertical-align:middle;
  margin:8px 0 0 0;
  cursor:pointer;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .benefits-state{
  font-weight:700;
  color:#4285F4;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .benefits-state-list{
  margin:8px 0 0 0;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .benefits-state-list ul{
  display:block;
  font-size:0;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .benefits-state-list ul li{
  display:inline-block;
  padding:7px 12px;
  border:1px solid #EAEAEA;
  border-radius:5px;
  margin:0 4px 0 0;
  font-size:12px;
  font-weight:400;
  color:#000000;
  cursor:pointer;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .benefits-state-list ul li:first-child{
  border:none;
  background:#5664D2;
  color:#ffffff;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .benefits-state-list ul li:last-child{
  margin:0;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .btn-review-memo{
  display:block;
  width:38px;
  height:32px;
  background:#5664D2;
  text-align:center;
  border-radius:5px;
}
.ai-widget-popup .popup-contents .popup-contents-main table tbody td .btn-review-memo .nd-icon{
  width:20px;
  height:20px;
  color:#ffffff;
  vertical-align:middle;
}

.ai-widget-popup .popup-contents .popup-close{
  display:block;
  font-size:0;
}
.ai-widget-popup .popup-contents .popup-close .btn-popup-close{
  display:block;
  width:52px;
  height:38px;
  background:#5664D2;
  border-radius:5px;
  font-size:13px;
  font-weight:400;
  color:#ffffff;
  text-align:center;
  line-height:38px;
  margin:0 auto;
}
.ai-widget-popup .popup-contents .popup-contents-main table.ai-preview {
  border: none;
  td.viewer {
    height: 580px;
    border: none;
    padding: 0;
  }
}

.history-window-popup .history-popup-contents .popup-contents-main img {
  width: 100%;
  height: auto;
}