@charset "UTF-8";
/* bg animation */
@keyframes floating {
  0% {
    transform: rotate(0deg) translate(-10px) rotate(0deg); }
  100% {
    transform: rotate(1turn) translate(-10px) rotate(-1turn); } }

/* 좌측 사이드 메뉴 */
.aside-wrap .aside-menu ul.depth1 li .material-icons {
  margin-bottom: 4px;
  font-size: 28px; }

/* 헤더 */
.header {
  padding: 0 30px; }
  .header .top-logo h1 {
    line-height: 64px; }
    .header .top-logo h1 img {
      height: 24px;
      vertical-align: middle; }
  .header .top-gnb {
    margin-top: -12px; }
    .header .top-gnb i.material-icons {
      font-size: 18px; }

.btn-box button {
  margin-left: 10px; }

.btn-box button:first-child {
  margin-left: 0px; }

/* 공통 > 페이지하단 > pagination */
.pagination ul li.btn-more {
  font-size: 0; }

/* 로그인, 회원가입 공통 */
.wrap-sign-out {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  padding: 0; }
  .wrap-sign-out .sign-content {
    position: relative;
    width: 360px;
    height: 650px; }
    .wrap-sign-out .sign-content .sign-content-bg {
      box-sizing: border-box; }
      .wrap-sign-out .sign-content .sign-content-bg > span {
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont1 {
        top: -100px;
        right: -100px;
        background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
        animation: floating 7s infinite; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont2 {
        width: 20px;
        height: 20px;
        animation: floating 9s infinite;
        top: 150px;
        right: -150px;
        background: #04a9f5; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont3 {
        width: 20px;
        height: 20px;
        animation: floating 7s infinite;
        left: -150px;
        bottom: 150px;
        background: #1de9b6; }
      .wrap-sign-out .sign-content .sign-content-bg .bg-cont4 {
        left: -100px;
        bottom: -100px;
        background: linear-gradient(-135deg, #899fd4, #a389d4);
        animation: floating 9s infinite; }
    .wrap-sign-out .sign-content .sign-content-card {
      position: relative;
      width: 100%;
      height: auto;
      min-height: 640px;
      background-color: #ffffff;
      padding: 30px;
      box-sizing: border-box;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 30px; }
      .wrap-sign-out .sign-content .sign-content-card > h3 {
        display: block;
        font-size: 24px;
        font-weight: 700;
        color: #000000;
        padding: 0 0 10px 0; }
      .wrap-sign-out .sign-content .sign-content-card .sign-form {
        display: block;
        padding: 10px 20px;
        width: 100%; }
        .wrap-sign-out .sign-content .sign-content-card .sign-form dl {
          width: 100%;
          height: auto;
          margin: 20px 0 0;
          padding: 0 15px; }
          .wrap-sign-out .sign-content .sign-content-card .sign-form dl dt {
            width: 100%;
            height: 100%; }
            .wrap-sign-out .sign-content .sign-content-card .sign-form dl dt input {
              width: 100%;
              height: 50px;
              border-radius: 5px;
              background-color: #ffffff;
              border: 1px solid #eeeeee;
              padding: 0 12px;
              font-size: 12px;
              font-weight: 400;
              color: #74788D;
              box-sizing: border-box; }
        .wrap-sign-out .sign-content .sign-content-card .sign-form dl:first-child {
          margin-top: 10px; }
      .wrap-sign-out .sign-content .sign-content-card .guide-text {
        margin: 10px 35px 0;
        font-weight: 400; }
      .wrap-sign-out .sign-content .sign-content-card .sign-help {
        display: block;
        padding: 0 0 10px; }
        .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-auto {
          display: inline-block;
          font-size: 13px;
          font-weight: 400;
          color: #343A40; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-auto .input-sign-auto {
            width: 16px;
            height: 16px;
            margin: 0 8px 0 0;
            background-size: 16px 16px;
            vertical-align: middle; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-auto .sign-auto-text {
            vertical-align: text-top; }
        .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-util {
          display: inline-block;
          float: right;
          font-size: 13px;
          font-weight: 400;
          color: #000000; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-util > a {
            vertical-align: middle; }
          .wrap-sign-out .sign-content .sign-content-card .sign-help .sign-util .bar {
            display: inline-block;
            width: 1px;
            height: 10px;
            background-color: #000000;
            margin: 0 6px;
            vertical-align: middle; }
      .wrap-sign-out .sign-content .sign-content-card .btn-box {
        display: block;
        width: 100%;
        margin: 10px 0 0;
        padding: 0 30px;
        box-sizing: border-box; }
        .wrap-sign-out .sign-content .sign-content-card .btn-box .save-button {
          display: block;
          width: 100%;
          height: 50px;
          background-color: #5664D2;
          border-radius: 5px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer; }
        .wrap-sign-out .sign-content .sign-content-card .btn-box > a {
          display: block;
          width: 100%;
          height: 46px;
          background-color: #ffffff;
          border: 1px solid #5664D2;
          border-radius: 5px;
          box-sizing: border-box;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #5664D2;
          margin: 10px 0 0;
          line-height: 46px; }
        .wrap-sign-out .sign-content .sign-content-card .btn-box .sign-in {
          display: block;
          padding: 40px 0 0;
          font-size: 12px;
          color: #000000;
          text-align: center; }
          .wrap-sign-out .sign-content .sign-content-card .btn-box .sign-in > span {
            color: #74788D;
            margin: 0 6px 0 0; }
          .wrap-sign-out .sign-content .sign-content-card .btn-box .sign-in strong {
            font-weight: 700;
            color: #000000; }
  .wrap-sign-out .is-save {
    height: 20px !important;
    margin-top: -10px; }
    .wrap-sign-out .is-save .form-group {
      position: absolute;
      right: 15%; }
  .wrap-sign-out input[type="checkbox"] {
    position: absolute; }

/* 웰컴 */
.welcome-content {
  position: relative;
  width: 480px;
  height: 640px;
  background: #ffffff;
  padding: 30px 30px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  font-size: 13px; }
  .welcome-content .welcome-content-bg {
    display: flex;
    width: 420px;
    height: 580px;
    justify-content: center;
    align-items: center; }
    .welcome-content .welcome-content-bg .welcome-content-wrap {
      width: 100%;
      flex-basis: 100%; }
      .welcome-content .welcome-content-bg .welcome-content-wrap .welcome-txt {
        padding-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.6;
        text-align: center; }
      .welcome-content .welcome-content-bg .welcome-content-wrap .btn-box {
        width: 100%;
        margin: 30px 0 10px;
        padding: 10px 0;
        text-align: center; }
        .welcome-content .welcome-content-bg .welcome-content-wrap .btn-box .save-button {
          display: block;
          width: 100%;
          height: 50px;
          line-height: 48px;
          background-color: #5664D2;
          border-radius: 5px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer; }
      .welcome-content .welcome-content-bg .welcome-content-wrap .welcome-bottom {
        margin: 10px 0 0; }
        .welcome-content .welcome-content-bg .welcome-content-wrap .welcome-bottom .welcome-link-guide {
          padding: 10px 0;
          font-weight: 400;
          text-align: center;
          text-decoration: underline;
          cursor: pointer; }
        .welcome-content .welcome-content-bg .welcome-content-wrap .welcome-bottom .welcome-info {
          line-height: 1.6;
          margin: 20px 10px 0;
          font-weight: 400; }
          .welcome-content .welcome-content-bg .welcome-content-wrap .welcome-bottom .welcome-info .welcome-info-list {
            padding: 5px 0 0; }
            .welcome-content .welcome-content-bg .welcome-content-wrap .welcome-bottom .welcome-info .welcome-info-list .welcome-info-item {
              padding: 2px 0; }

/* 약관 및 정책 페이지 */
.agree-content {
  width: 90%;
  height: 90%; }
  .agree-content .welcome-content-bg {
    width: 100%;
    height: 100%; }
    .agree-content .welcome-content-bg .welcome-content-wrap {
      height: 100%; }
      .agree-content .welcome-content-bg .welcome-content-wrap .terms-privacy {
        height: 100%; }

/* 리뷰답글 > 리뷰리스트 */
.review-search-box .section-box .btn-search {
  position: relative;
  right: auto;
  bottom: auto;
  padding: 20px 0 10px;
  text-align: center; }

.review-search-box .section-box .btn-toggle {
  margin-bottom: -36px; }

.review-list-box .review-contents-wrap .review-contents .review-contents-box .review-contents-area .review-content .form-control {
  border-radius: 0; }

/* 리뷰답글 > 설정 */
.page-reply-control .row {
  align-items: center; }
  .page-reply-control .row input[type="checkbox"] {
    position: absolute; }
  .page-reply-control .row label {
    line-height: 28px;
    text-align: left; }
  .page-reply-control .row .custom-control.custom-toggle.custom-toggle-sm {
    line-height: 18px; }
    .page-reply-control .row .custom-control.custom-toggle.custom-toggle-sm .custom-control-description {
      vertical-align: middle; }
