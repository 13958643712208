@charset "UTF-8";
/* 메인 상단 */
.layer-black {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ccc;
  opacity: 0.6; }
  .layer-black.show {
    display: block; }
  .layer-black.hide {
    display: none; }

.btn-kakao-ch {
  position: fixed;
  bottom: 40px;
  left: 20px;
  z-index: 9999; }

.btn-kakao-ch a img {
  display: inline-block;
  width: 70px;
  height: 70px; }

.wrap-sign-out .btn-kakao-ch {
  left: 50%;
  bottom: 10%;
  margin-left: -35px; }

.tooltip-inner .wrap-sign-out-tooltip {
  font-weight: bold;
  color: #000; }

/* 높이가 800px 이하일 때 스타일 변경 */
@media screen and (max-height: 1000px) {
  .wrap-sign-out .btn-kakao-ch {
    left: unset;
    bottom: unset;
    top: 5%;
    right: 10%; } }

/* 높이가 600px 이하일 때 스타일 변경 */
/* 높이가 400px 이하일 때 스타일 변경 */
