.dropzone {
  width: 100%;
  height: 250px;
  border: 3px dashed #5664D2;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  .upload-icon {
    color: #5664D2;
    margin-top: 50px;
  }
  p {
    font-size: 16px;
    color: #282835;
  }
  p.small-info {
    font-size: 16px;
  }
}
.uploadContainer {
  margin-top: 10px;
  .upload-list {
    display: block;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    .item {
      height: 100%;
      padding: 10px;
      overflow: hidden;
      .img-box {
        height: 100%;
        width: 70px;
        float: left;
        text-align: center;
        background-color: #696cab;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
      .info-box {
        float: left;
        padding-left:10px;
        width: calc(100% - 80px);
        position: relative;
        p {
          font-size: 14px;
        }
        p.small-info {
          font-size: 11px;
          color: #666;
        }
        button.btn-remove {
          position: absolute;
          top: 10px;
          right: 0px;
          color: #bb1d5a;
          background: none;
          border: none;
        }
      }
    }
    .item:hover {
      background-color: #f8fafc;
    }
  }
}

