.main-content {
  height: calc(100% - 70px);
}
.banner-maker {
  height: 100%;
}
.editor-wrap {
  display: flex;
  height: 100%;
  flex-shrink: 1;
  .side-area {
    width: 319px;
    border-right:1px solid #ccc;
    flex-shrink: 0;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    .tabs-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      position: sticky;
      .tab {
        width: 100%;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        flex-shrink: 1;
        background-color: #eee;
        color: #666;
        &.active {
          background-color: #fff;
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
  .main-area {
    //width: calc(100% - 320px);
    width: calc(100% - 570px);
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    background-color: #fff;
    .viewer-box {
      padding: 30px;
      width: 100%;
      height: 100%;
      .banner-editor {
        height: 100%;
        .filerobotImageEditor {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .toggle-side-area {
    flex-shrink: 2;
    width: 250px;
    background-color: #fff;
    padding: 10px;
    cursor: pointer;
    border-left: 5px solid #ccc;
    z-index: 999;
  }
}

.tab-cont-box {
  min-height: calc(100% - 70px);
  position: relative;
  .uploder-wrap {
    position:fixed;
    bottom:70px;
    left:128px;
    width: 320px;
  }
}

.banner-list {
  padding-bottom: 300px
}
.banner-list > ul > li {
  position: relative;
  padding: 10px 10px 10px 17px;
  border-bottom: 1px solid #ccc;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(-90deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transition: transform 0.3s ease;
  }
  .page-num {
    position: absolute;
    right: 10px;
    bottom: 7px;
  }
  .media {
    align-items: center;
    margin: 10px 10px 10px 0px;
    width: calc(100% - 10px);
    height: 120px;
    text-align: center;
    img {
      margin: 0 auto;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}