@charset "UTF-8";
/* benefits */
/* 포인트 지급 수준 */
.benefits-box .table-sub-tit {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #74788D;
  padding: 7px 0 0 0; }
  .benefits-box .table-sub-tit .detail-more {
    position: relative;
    display: inline-block;
    margin: 0 0 0 8px;
    font-size: 12px;
    font-weight: 700;
    color: #5664D2; }
  .benefits-box .table-sub-tit .detail-more:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #5664D2; }

.benefits-box .section-box:nth-child(1) {
  margin: 0 0 30px 0; }

.benefits-box .section-box:nth-child(2) {
  margin: 0 0 30px 0; }

.benefits-box table tr td {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  text-align: center; }
  .benefits-box table tr td input[type="number"] {
    border: 1px solid #CED4DA;
    padding: 0 12px;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    text-align: right;
    box-sizing: border-box; }

.benefits-box .table-guide {
  display: block;
  padding: 6px 0 7px 0; }
  .benefits-box .table-guide > p {
    font-size: 12px;
    font-weight: 400;
    color: #74788D; }

.benefits-box .table-switch {
  display: block;
  margin: 7px 0 14px 0; }
  .benefits-box .table-switch > span {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    padding: 0 0 0 8px;
    vertical-align: bottom; }

/* 추가 포인트 */
.add-mileage-table {
  margin: 0 0 20px 0; }
  .add-mileage-table .basic-review {
    padding: 0 0 20px 0; }
  .add-mileage-table .sns-review {
    padding: 20px 0 0 0; }
  .add-mileage-table .section-tit {
    padding: 0 0 17px 0; }
  .add-mileage-table table tr th {
    width: 290px; }
    .add-mileage-table table tr th > span {
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      padding: 0 0 0 8px;
      vertical-align: middle; }
    .add-mileage-table table tr th .tooltip-icon {
      padding: 0 0 0 4px; }
  .add-mileage-table table tr td {
    text-align: left; }
    .add-mileage-table table tr td .option-value {
      display: block;
      line-height: 28px;
      vertical-align: middle; }
      .add-mileage-table table tr td .option-value .table-input-number {
        display: inline-block;
        width: 100px;
        height: 28px;
        border: 1px solid #CED4DA;
        text-align: right;
        padding: 0 10px;
        box-sizing: border-box;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 400;
        color: #000000; }
      .add-mileage-table table tr td .option-value .btn-option {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 8px; }
        .add-mileage-table table tr td .option-value .btn-option button {
          margin: 0 0 0 4px; }
        .add-mileage-table table tr td .option-value .btn-option button:first-child {
          margin: 0; }
    .add-mileage-table table tr td .option-value.add-option {
      margin: 8px 0 0 0; }

/* 리뷰 정책(혜택) */
.review-policy-table {
  margin: 0 0 20px 0; }
  .review-policy-table table tr td {
    display: block;
    text-align: left;
    height: 28px;
    padding: 15px 20px; }
    .review-policy-table table tr td .option-value {
      display: inline-block;
      vertical-align: middle; }
      .review-policy-table table tr td .option-value .btn-switch {
        margin: 0 8px 0 0; }
      .review-policy-table table tr td .option-value .table-input-number {
        display: inline-block;
        width: 45px;
        height: 28px;
        border: 1px solid #CED4DA;
        text-align: right;
        padding: 0 10px;
        box-sizing: border-box;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 400;
        color: #000000; }
      .review-policy-table table tr td .option-value .select-option {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0 0 5px; }
        .review-policy-table table tr td .option-value .select-option select {
          width: 100px;
          height: 28px;
          border: 1px solid #CED4DA;
          box-sizing: border-box;
          padding: 0 20px 0 7px;
          background-position: right 7px center; }
      .review-policy-table table tr td .option-value .tooltip-icon {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0 0 4px; }

/* commonDesign */
.common-design-box .section-box:nth-child(1) {
  margin: 0 0 30px 0; }

.common-design-box .common-table {
  margin: 0 0 20px 0; }
  .common-design-box .common-table table tbody tr th {
    width: 200px; }
  .common-design-box .common-table table tbody tr td input[type='text'] {
    width: 300px;
    height: 38px; }
  .common-design-box .common-table table tbody tr td input[type='number'] {
    width: 300px;
    height: 38px; }
  .common-design-box .common-table table tbody tr td .color-picker .btn-color-picker-wrap {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 38px;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
    vertical-align: middle; }
    .common-design-box .common-table table tbody tr td .color-picker .btn-color-picker-wrap .btn-color-picker {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 5px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
  .common-design-box .common-table table tbody tr td .color-picker .input-color-picker {
    margin: 0 0 0 10px; }
  .common-design-box .common-table table tbody tr td .info-id-select {
    display: inline-block;
    width: 300px;
    height: 38px;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    padding: 0 25px 0 10px;
    font-size: 12px;
    font-weight: 400;
    color: #74788D;
    line-height: 38px;
    vertical-align: middle;
    cursor: -mileageer; }
    .common-design-box .common-table table tbody tr td .info-id-select > span {
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      padding: 0 0 0 8px;
      vertical-align: middle; }
  .common-design-box .common-table table tbody tr td .option-value {
    font-size: 0; }
    .common-design-box .common-table table tbody tr td .option-value span {
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      padding: 0 0 0 8px;
      vertical-align: middle; }

.common-design-box .detail-view-table {
  margin: 0 0 20px 0; }
  .common-design-box .detail-view-table .section-tit .table-sub-tit {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #74788D;
    padding: 7px 0 0 0; }
  .common-design-box .detail-view-table table tbody tr th {
    width: 200px; }
  .common-design-box .detail-view-table table tbody tr td .option-value span {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    padding: 0 0 0 8px;
    vertical-align: middle; }

/* induce */
.review-induce-box .section-box:nth-child(1) {
  margin: 0 0 30px 0; }

.review-induce-box .kakao-notice-table {
  position: relative;
  width: 100%; }

.review-induce-box table {
  width: 100%;
  border: 1px solid #EFF2F7;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse; }
  .review-induce-box table tr {
    width: 100%;
    text-align: left; }
    .review-induce-box table tr th {
      width: 200px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      border: 1px solid #EAEAEA;
      padding: 0 0 0 20px;
      box-sizing: border-box; }
    .review-induce-box table tr td {
      padding: 10px;
      border: 1px solid #EAEAEA; }
      .review-induce-box table tr td input[type="text"] {
        width: 300px;
        height: 38px; }

.review-induce-box .caller-id-table {
  position: relative;
  width: 100%;
  margin: 34px 0 0 0; }

.review-induce-box .user-id {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  vertical-align: middle;
  line-height: 1; }

.review-induce-box .user-phone {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  vertical-align: middle;
  line-height: 1; }

.review-induce-box .state-text {
  font-size: 13px;
  font-weight: 500;
  color: #4AA3FF;
  margin: 0 0 0 20px;
  vertical-align: middle;
  line-height: 1; }

.review-induce-box .table-radio-wrap {
  display: block;
  padding: 0 0 7px 0; }
  .review-induce-box .table-radio-wrap label {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    vertical-align: middle;
    padding: 0 0 0 30px;
    min-height: 20px;
    background-image: url("/images/img/table_radio.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: left center;
    cursor: -mileageer; }
    .review-induce-box .table-radio-wrap label .table-input-number {
      width: 45px;
      height: 28px;
      border: 1px solid #CED4DA;
      text-align: right;
      padding: 0 10px;
      box-sizing: border-box;
      vertical-align: baseline;
      font-size: 14px;
      font-weight: 400;
      color: #000000; }
  .review-induce-box .table-radio-wrap label.on {
    background-image: url("/images/img/table_radio_on.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: left center; }

.review-induce-box .table-radio-wrap:last-child {
  padding: 0; }

/* infoEdit */
.basic-info-box table {
  width: 100%;
  border: 1px solid #EFF2F7;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse; }
  .basic-info-box table tr {
    width: 100%;
    text-align: left; }
    .basic-info-box table tr th {
      width: 200px;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      border: 1px solid #EAEAEA;
      padding: 0 0 0 20px;
      box-sizing: border-box; }
    .basic-info-box table tr td {
      padding: 10px;
      border: 1px solid #EAEAEA; }
      .basic-info-box table tr td input[type="text"] {
        width: 300px;
        height: 38px; }
      .basic-info-box table tr td input[type="password"] {
        width: 300px;
        height: 38px; }
      .basic-info-box table tr td .info-id-select {
        display: inline-block;
        width: 300px;
        height: 38px;
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        padding: 0 25px 0 10px;
        font-size: 12px;
        font-weight: 400;
        color: #74788D;
        line-height: 38px;
        vertical-align: middle;
        cursor: pointer; }
      .basic-info-box table tr td .btn-password-type {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0 0 6px; }
        .basic-info-box table tr td .btn-password-type .nd-icon {
          width: 15px;
          height: 15px;
          color: #CED4DA; }
      .basic-info-box table tr td .btn-password-type.active .nd-icon {
        color: #000000; }
      .basic-info-box table tr td .logo-upload img {
        vertical-align: middle; }
      .basic-info-box table tr td .logo-upload .btn-img-upload {
        vertical-align: middle; }

/* snsReview */
.sns-review-box .section-box:nth-child(1) {
  margin: 0 0 30px 0; }

.sns-review-box .section-box:nth-child(2) {
  margin: 0 0 30px 0; }

.sns-review-box .section-box:nth-child(2) {
  margin: 0 0 30px 0; }

.sns-review-box .account-connect-table table tbody tr th {
  width: 200px; }

.sns-review-box .account-connect-table table tbody tr td {
  padding: 20px; }
  .sns-review-box .account-connect-table table tbody tr td .table-cont {
    display: block; }
  .sns-review-box .account-connect-table table tbody tr td > span {
    padding: 0 0 0 10px;
    vertical-align: middle; }
  .sns-review-box .account-connect-table table tbody tr td > a {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #74788D;
    margin: 6px 0 0 0; }
  .sns-review-box .account-connect-table table tbody tr td > a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #74788D; }
  .sns-review-box .account-connect-table table tbody tr td .account-connect {
    display: inline-block;
    padding: 10px 12px;
    background: #5664D2;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff; }

.sns-review-box .certify-condition-table {
  margin: 0 0 20px 0; }
  .sns-review-box .certify-condition-table .all-product-apply {
    padding: 0 0 15px 0; }
  .sns-review-box .certify-condition-table table tbody td .hashtag-box {
    position: relative;
    width: 100%;
    min-height: 38px;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    text-align: left; }
    .sns-review-box .certify-condition-table table tbody td .hashtag-box .hashtag-box-icon {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 36px;
      height: 100%;
      background: #EFF2F7;
      margin: 0 3px 0 0;
      text-align: center;
      vertical-align: middle;
      border-right: 1px solid #CED4DA; }
      .sns-review-box .certify-condition-table table tbody td .hashtag-box .hashtag-box-icon .nd-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        color: #505D69; }
    .sns-review-box .certify-condition-table table tbody td .hashtag-box .option-wrap {
      display: inline-block;
      padding: 4px 0 4px 39px; }
      .sns-review-box .certify-condition-table table tbody td .hashtag-box .option-wrap .option-cont {
        position: relative;
        display: inline-block;
        padding: 3px 10px 3px 32px;
        margin: 3px 2px;
        background: #E0E3F7;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #5664D2;
        vertical-align: middle; }
        .sns-review-box .certify-condition-table table tbody td .hashtag-box .option-wrap .option-cont .nd-icon {
          position: absolute;
          top: 50%;
          left: 8px;
          margin-top: -8px;
          width: 16px;
          height: 16px; }
  .sns-review-box .certify-condition-table .category-apply {
    padding: 15px 0; }
    .sns-review-box .certify-condition-table .category-apply table tr td {
      text-align: center; }
      .sns-review-box .certify-condition-table .category-apply table tr td .rule-del {
        display: inline-block;
        height: 28px;
        padding: 0 8px;
        text-align: center;
        border: 1px solid #343A40;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 400;
        color: #000000; }
    .sns-review-box .certify-condition-table .category-apply table tr td:nth-child(2) {
      vertical-align: top; }
    .sns-review-box .certify-condition-table .category-apply table tr td:nth-child(3) {
      vertical-align: top; }
  .sns-review-box .certify-condition-table .btn-rule {
    position: relative;
    display: block;
    margin: 0 0 10px 0; }
    .sns-review-box .certify-condition-table .btn-rule .rule-del {
      display: inline-block;
      height: 28px;
      padding: 0 8px;
      border: 1px solid #343A40;
      border-radius: 5px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
      box-sizing: border-box; }
    .sns-review-box .certify-condition-table .btn-rule .rule-add {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -14px;
      padding: 0 12px 0 32px; }
      .sns-review-box .certify-condition-table .btn-rule .rule-add .nd-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        color: #ffffff; }
      .sns-review-box .certify-condition-table .btn-rule .rule-add > span {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        vertical-align: middle; }
  .sns-review-box .certify-condition-table .situation-apply {
    padding: 15px 0 0 0; }
  .sns-review-box .certify-condition-table table tr td {
    text-align: center; }
  .sns-review-box .certify-condition-table table tr td:nth-child(2) {
    vertical-align: top; }
  .sns-review-box .certify-condition-table table tr td:nth-child(3) {
    vertical-align: top; }
  .sns-review-box .certify-condition-table table tr .rule-del {
    display: inline-block;
    height: 28px;
    padding: 0 8px;
    text-align: center;
    border: 1px solid #343A40;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
    color: #000000; }

.sns-review-box .certify-condition-table.period table tr th {
  width: 200px; }

.sns-review-box .certify-condition-table.period table tr td .table-input-number {
  display: inline-block;
  width: 45px;
  height: 28px;
  border: 1px solid #CED4DA;
  text-align: right;
  padding: 0 10px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  color: #000000; }

.sns-review-box .certify-condition-table.period table tr td > span {
  vertical-align: middle; }

.sns-review-box .setting-link-table table tr th {
  width: 200px; }

.sns-review-box .setting-link-table table tr td {
  padding: 20px; }
  .sns-review-box .setting-link-table table tr td > a {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: #5664D2;
    vertical-align: middle; }
  .sns-review-box .setting-link-table table tr td > a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #5664D2; }

/* widget */
/* 상세보기 디자인 */
.widget-box .detail-view-table {
  margin: 0 0 20px 0; }
  .widget-box .detail-view-table .section-tit {
    padding: 0 0 14px 0; }
    .widget-box .detail-view-table .section-tit .table-sub-tit {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #74788D;
      padding: 7px 0 0 0; }
  .widget-box .detail-view-table .widget-pick-list {
    display: block;
    width: 100%;
    border: 1px solid #E8ECF4;
    border-bottom: none;
    box-sizing: border-box;
    padding: 8px 10px; }
    .widget-box .detail-view-table .widget-pick-list .pick-list-count {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: #74788D;
      line-height: 28px; }
      .widget-box .detail-view-table .widget-pick-list .pick-list-count > span {
        font-weight: 700;
        color: #5664D2; }
    .widget-box .detail-view-table .widget-pick-list .choice-num-view {
      display: inline-block;
      float: right; }
      .widget-box .detail-view-table .widget-pick-list .choice-num-view select {
        width: 112px;
        height: 28px;
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        padding: 0 20px 0 7px;
        background-position: right 7px center; }
  .widget-box .detail-view-table .btn-widget-add {
    display: inline-block;
    height: 28px;
    padding: 0 8px;
    border: 1px solid #343A40;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    background-color: #000000;
    line-height: 28px;
    box-sizing: border-box;
    margin: 0 7px; }
    .widget-box .detail-view-table .btn-widget-add .nd-icon {
      margin-left: 5px;
      width: 18px;
      height: 18px;
      color: #ffffff; }
  .widget-box .detail-view-table .btn-widget {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid #E8ECF4;
    border-bottom: none;
    box-sizing: border-box;
    padding: 8px 10px; }
    .widget-box .detail-view-table .btn-widget .widget-del {
      position: relative;
      display: inline-block;
      height: 28px;
      padding: 0 8px 0 20px;
      border: 1px solid #343A40;
      box-sizing: border-box;
      border-radius: 5px; }
      .widget-box .detail-view-table .btn-widget .widget-del .nd-icon {
        position: absolute;
        top: 50%;
        left: 5px;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        color: #FF3D60; }
      .widget-box .detail-view-table .btn-widget .widget-del > span {
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        line-height: 28px; }
    .widget-box .detail-view-table .btn-widget .widget-add {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -14px;
      padding: 0 12px 0 32px; }
      .widget-box .detail-view-table .btn-widget .widget-add .nd-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        color: #ffffff; }
      .widget-box .detail-view-table .btn-widget .widget-add > span {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        vertical-align: middle;
        line-height: 28px; }
  .widget-box .detail-view-table table tbody tr td {
    text-align: center;
    padding: 10px; }
    .widget-box .detail-view-table table tbody tr td .btn-wrap button {
      display: inline-block;
      height: 28px;
      padding: 0 8px;
      border: 1px solid #343A40;
      border-radius: 5px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      box-sizing: border-box;
      margin: 0 4px; }
    .widget-box .detail-view-table table tbody tr td .btn-wrap button:nth-child(1) {
      margin-left: 0; }

.widget-box .pagination {
  padding: 30px 0 0 0; }

/* wrightPost */
.write-post-box .section-box {
  margin: 0 0 30px 0; }

.write-post-box table td ul.phase-list {
  list-style: disc;
  margin-left: 15px; }
  .write-post-box table td ul.phase-list li {
    padding: 5px 0;
    text-align: left; }
    .write-post-box table td ul.phase-list li .nd-icon {
      margin-top: -2px;
      margin-left: 10px;
      vertical-align: middle; }

.write-post-box .review-limit-table {
  margin: 0 0 20px 0; }
  .write-post-box .review-limit-table table thead th {
    padding: 0; }
  .write-post-box .review-limit-table table thead td {
    vertical-align: top; }
    .write-post-box .review-limit-table table thead td > span {
      vertical-align: middle; }
    .write-post-box .review-limit-table table thead td .option-box {
      width: 100%;
      min-height: 38px;
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      padding: 4px 3px;
      text-align: left; }
      .write-post-box .review-limit-table table thead td .option-box .btn-option {
        display: inline-block;
        margin: 1px 2px 0 2px;
        vertical-align: top; }
      .write-post-box .review-limit-table table thead td .option-box .option-cont {
        position: relative;
        display: inline-block;
        padding: 3px 10px 3px 32px;
        margin: 3px 2px;
        background: #E0E3F7;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #5664D2; }
        .write-post-box .review-limit-table table thead td .option-box .option-cont .nd-icon {
          position: absolute;
          top: 50%;
          left: 8px;
          margin-top: -8px;
          width: 16px;
          height: 16px; }
    .write-post-box .review-limit-table table thead td .guide-text {
      padding: 6px 0 0 0; }
  .write-post-box .review-limit-table table thead td:nth-child(1) {
    padding: 0 0 0 20px;
    vertical-align: middle; }
  .write-post-box .review-limit-table table thead td:nth-child(2) {
    padding: 0 0 0 20px;
    vertical-align: middle; }
    .write-post-box .review-limit-table table thead td:nth-child(2) > span {
      padding: 0 0 0 8px; }
  .write-post-box .review-limit-table .table-guide {
    display: block;
    padding: 15px 0 0 20px; }
    .write-post-box .review-limit-table .table-guide .review-policy-link {
      padding: 0 0 10px 0; }
      .write-post-box .review-limit-table .table-guide .review-policy-link > strong {
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        vertical-align: middle; }
      .write-post-box .review-limit-table .table-guide .review-policy-link > a {
        position: relative;
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        color: #5664D2;
        margin: 0 0 0 8px;
        vertical-align: middle; }
      .write-post-box .review-limit-table .table-guide .review-policy-link > a:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: #5664D2; }
    .write-post-box .review-limit-table .table-guide > p {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #74788D; }
  .write-post-box .review-limit-table .btn-save {
    padding: 16px 0 0 0; }

.write-post-box .substitute-table {
  margin: 0 0 20px 0; }
  .write-post-box .substitute-table .btn-substitute {
    position: relative;
    display: block;
    margin: 0 0 10px 0; }
    .write-post-box .substitute-table .btn-substitute .substitute-del {
      display: inline-block;
      height: 28px;
      padding: 0 8px;
      border: 1px solid #343A40;
      border-radius: 5px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      box-sizing: border-box; }
    .write-post-box .substitute-table .btn-substitute .substitute-add {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -14px; }
  .write-post-box .substitute-table table tbody tr td {
    text-align: center; }

.write-post-box .auto-reply-table {
  margin: 0 0 20px 0; }
  .write-post-box .auto-reply-table .section-tit {
    padding: 0 0 17px 0; }
  .write-post-box .auto-reply-table .auto-reply-use {
    padding: 0 0 20px 0; }
    .write-post-box .auto-reply-table .auto-reply-use table tr th > span {
      padding: 0 0 0 8px;
      vertical-align: middle; }
  .write-post-box .auto-reply-table .auto-reply-list {
    padding: 20px 0 0 0; }
    .write-post-box .auto-reply-table .auto-reply-list .btn-reply {
      position: relative;
      display: block;
      margin: 0 0 10px 0; }
      .write-post-box .auto-reply-table .auto-reply-list .btn-reply .reply-del {
        display: inline-block;
        height: 28px;
        padding: 0 8px;
        border: 1px solid #343A40;
        border-radius: 5px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        box-sizing: border-box; }
      .write-post-box .auto-reply-table .auto-reply-list .btn-reply .reply-default {
        display: inline-block;
        height: 28px;
        padding: 0 8px;
        border: 1px solid #343A40;
        border-radius: 5px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        box-sizing: border-box;
        float: right; }
      .write-post-box .auto-reply-table .auto-reply-list .btn-reply .reply-add {
        float: right;
        min-width: 64px;
        box-sizing: border-box;
        margin: 0 0 0 4px; }
    .write-post-box .auto-reply-table .auto-reply-list table tbody tr td {
      text-align: center; }
      .write-post-box .auto-reply-table .auto-reply-list table tbody tr td .rating-select select {
        width: 150px;
        height: 38px;
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        padding: 0 20px 0 7px;
        background-position: right 7px center; }
      .write-post-box .auto-reply-table .auto-reply-list table tbody tr td .reply-content textarea {
        width: 100%;
        min-height: 70px;
        padding: 7px 13px;
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        color: #000000;
        opacity: 20%; }
      .write-post-box .auto-reply-table .auto-reply-list table tbody tr td .reply-content.active textarea {
        opacity: 1; }

.section-box .inline-input-sm {
  display: inline-block;
  width: 70px; }

.widget-btn-box button {
  margin: 4px; }

.delete-account {
  float: right;
  padding-right: 10px;
  color: #42484e; }

.order-org-amount {
  font-size: 16px;
  text-decoration: line-through;
  color: #bc3357; }

.order-amount {
  color: #000;
  font-size: 20px;
  font-weight: 700; }

.event-info-text {
  font-size: 16px;
  margin-top: 5px; }
  .event-info-text strong {
    color: #bc3357; }
  .event-info-text i {
    text-decoration: underline; }

.plan.animated-box {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease; }

.plan.animated-box.expanded {
  height: 750px;
  margin-bottom: 20px; }

@font-face {
  font-family: 'SBAggroB';
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.dday-count {
  font-family: 'SBAggroB','Noto Sans KR', Malgun Gothic,"맑은 고딕",AppleGothic,Dotum,"돋움",sans-serif;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 1px; }
  .dday-count i {
    font-size: 0.8em;
    vertical-align: middle;
    font-style: normal;
    display: inline-block;
    margin-left: 3px; }

.banner-startup-box {
  cursor: pointer;
  width: 1200px;
  position: relative;
  margin-top: 20px; }
  .banner-startup-box .dday-count {
    display: block;
    vertical-align: middle;
    position: absolute;
    top: 220px;
    width: 100%;
    text-align: center;
    font-size: 70px;
    color: #54EAFE !important;
    letter-spacing: 3px; }
    .banner-startup-box .dday-count i {
      font-size: 20px;
      vertical-align: middle;
      font-style: normal;
      display: inline-block;
      margin-left: 8px; }
