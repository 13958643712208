@charset "UTF-8";
.section-tit .table-sub-tit {
  display: block;
  padding: 0;
  color: #74788D;
  font-size: 13px;
  font-weight: 400; }

.order-box {
  padding-bottom: 100px;
  /* 플랜선택하기 */
  /* 약관동의하기 */ }
  .order-box strong {
    font-weight: 600; }
  .order-box .order-date {
    display: inline-block;
    margin: 0 0 0 10px; }
    .order-box .order-date .order-date-txt {
      display: inline-block;
      vertical-align: middle;
      padding: 0 5px; }
  .order-box .plan {
    display: flex;
    margin: 20px 0 0; }
    .order-box .plan .plan-section {
      box-sizing: border-box;
      margin: 0 20px 0 0;
      padding: 40px 35px;
      background: #F4F5F8;
      border-radius: 40px; }
      .order-box .plan .plan-section .plan-item-tit {
        margin-bottom: 20px;
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        text-align: center; }
      .order-box .plan .plan-section .plan-section-wrap {
        display: flex; }
        .order-box .plan .plan-section .plan-section-wrap .plan-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          box-sizing: border-box;
          width: 402px;
          height: 402px;
          margin: 0 5px;
          padding: 20px;
          border: 1px solid #eeeeee;
          background: #ffffff;
          border-radius: 20px;
          cursor: pointer; }
          .order-box .plan .plan-section .plan-section-wrap .plan-item:hover {
            border-color: #5664D2;
            background-color: #F0F2FD; }
          .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-selected {
            display: none;
            position: absolute;
            top: 15px;
            right: 15px;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background: #5664D2 url("/static/media/input_checkbox_on.723c9afd.svg") no-repeat center center;
            background-size: 20px;
            font-size: 0; }
          .order-box .plan .plan-section .plan-section-wrap .plan-item.selected {
            border-color: #5664D2;
            background-color: #F0F2FD; }
            .order-box .plan .plan-section .plan-section-wrap .plan-item.selected .plan-selected {
              display: block; }
          .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-top {
            position: relative;
            height: 126px;
            padding: 10px 10px 0; }
            .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-top .plan-tag .plan-tag-txt {
              display: inline-block;
              box-sizing: border-box;
              height: 30px;
              padding: 0 11px;
              border-radius: 15px;
              border: 2px solid #000000;
              color: #000000;
              font-size: 16px;
              font-weight: 500; }
            .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-top .plan-cost {
              margin: 5px 0 0;
              color: #000000;
              font-size: 16px;
              font-weight: 400; }
              .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-top .plan-cost .plan-cost-val {
                margin: 0 5px 0 0;
                font-size: 30px;
                font-weight: 600;
                vertical-align: middle; }
            .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-top .plan-cost-sub {
              margin-top: -3px;
              color: #999999;
              font-size: 14px;
              font-weight: 400; }
          .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-content {
            height: 170px;
            padding: 0 10px; }
            .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-content .order-list {
              display: flex;
              justify-content: space-between;
              line-height: 1.42;
              padding: 5px 0;
              font-size: 14px;
              font-weight: 400; }
          .order-box .plan .plan-section .plan-section-wrap .plan-item .plan-item-bottom .btn-plan-select {
            display: block;
            width: 100%;
            height: 56px;
            border-radius: 4px;
            background: #5664D2;
            color: #ffffff;
            font-size: 16px;
            font-weight: 400; }
      .order-box .plan .plan-section .plan-desc {
        padding: 30px 10px 0;
        line-height: 1.7;
        color: #74788D;
        font-size: 13px;
        font-weight: 400; }
        .order-box .plan .plan-section .plan-desc strong {
          color: #000000; }
  .order-box .terms-privacy {
    overflow: auto;
    height: 350px;
    padding: 20px 10px;
    box-sizing: border-box;
    border: 1px solid #eeeeee; }
    .order-box .terms-privacy h2 {
      font-size: 20px;
      margin-bottom: 10px; }
    .order-box .terms-privacy h3 {
      font-size: 17px;
      margin-bottom: 7px; }
  .order-box .serviceAgreeCheck {
    margin: 20px 20px 0 0; }
    .order-box .serviceAgreeCheck label {
      font-size: 14px;
      margin-left: 5px; }
