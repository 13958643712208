.wrap-sign-out .sign-content {

   width:480px;
   height:auto;
   min-height:600px;

   .sign-form {

      padding:30px 30px;

      input {
         width:auto;
      }

      .sign-form-box {
         margin:0;
         box-sizing:border-box;
         .sign-form-box-wrap {
            box-sizing:border-box;
            border-radius:5px;
            border-top:1px solid #eeeeee;
            border-left:1px solid #eeeeee;
            border-right:1px solid #eeeeee;
            border-bottom:1px solid #eeeeee;
            .sign-form-row {
               padding:15px;
               box-sizing:border-box;
               border-bottom:1px solid #eeeeee;
               &:last-child {
                  border-bottom:none;
               }
               a {
                  text-decoration:underline;
               }
               .form-group {
                  margin-top:10px;
                  margin-bottom:0;
               }
            }
         }
      }
   }



   .sign-content-card {
      h3 {
         margin-top:90px;
         margin-bottom:20px;
         font-size:24px;
         font-weight:600;
      }
      p.welcome-text {
         padding:50px 0 30px 0;
         text-align:center;
         font-size:18px;
         font-weight:400;
         line-height:1.8;
      }

      .btn-box {
         position:absolute;
         bottom:50px;
         left:0;
         text-align:center;
      }
   }
}
